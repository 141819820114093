<template>
  <Toast position="top-right" />
  <ConfirmDialog></ConfirmDialog>
  <h2>实验员任务</h2>
  <div>
    <label>请选择工单状态:</label>
    <select v-model.number="status">
      <option value="-3" disabled>请选择</option>
      <option v-for="item in reportFlags" :value="item.id" :key="item.id">
        {{ item.id }}{{ item.name }}
      </option>
    </select>
  </div>
  <button :disabled="status !== 0" @click="acceptTaskMany">批量接收</button>
  <button class="grap" @click="urgent()">加急</button>
  <button class="grap" @click="cancelUrgent()">取消加急</button>
  <button class="grap" @click="finish">标识完结</button>
  <button class="grap" @click="unFinish">重置转为未接收</button>
  <div class="card">
    <DataTable
      :rowHover="true"
      :value="taskItems"
      v-model:selection="selectedItem"
      dataKey="sample.id"
    >
      <Column selectionMode="multiple" headerStyle="width: 2em"></Column>
      <Column field="sample" header="报告编号">
        <template #body="slotProps">
          {{ sampleno(slotProps.data.sample) }}
        </template>
      </Column>
      <Column field="sample" header="委托编号">
        <template #body="slotProps">
          {{ trustno(slotProps.data.sample) }}
        </template>
      </Column>
      <Column field="wpsfile.status" header="报告状态">
        <template #body="slotProps">
          <ReportStatus :num="slotProps.data.wpsfile.status" />
        </template>
      </Column>
      <Column field="wpsfile.urgent" header="是否加急">
        <template #body="slotProps">
          <span :class="{ red: slotProps.data.wpsfile.urgent === 1 }">{{
            slotProps.data.wpsfile.urgent === 1 ? "加急" : ""
          }}</span>
        </template>
      </Column>
      <Column field="sample" header="中间报告">
        <template #body="slotProps">
          {{ middlereport(slotProps.data.sample) }}
        </template>
      </Column>
      <Column field="sample.reportdate" header="报告日期">
        <template #body="slotProps">
          <DateLabel :mydate="slotProps.data.sample.reportdate" />
        </template>
      </Column>
      <Column field="wpsfile" header="查看报告">
        <template #body="slotProps">
          <ViewReport :wpsfile="slotProps.data.wpsfile" />
        </template>
      </Column>
      <Column field="sample.trustdate" header="委托日期"> </Column>
      <Column field="sample.samplenickname" header="样品名称"></Column>
      <!-- <Column field="sample.trust.projectname" header="工程名称"></Column> -->
    </DataTable>
  </div>
  <Paginator
    :pageLinkSize="7"
    :rows="10"
    :totalRecords="count"
    @page="onPage($event)"
  >
  </Paginator>
  <p>总数:{{ count }}条,当前第{{ curPage }}页</p>
</template>

<script>
import { baseUrl, reportFlags } from "@/config.js";
import auth from "@/store/modules/auth";
import { onMounted, ref, watchEffect, onUnmounted } from "vue";
import Paginator from "primevue/paginator";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ReportStatus from "@/components/ReportStatus";
import DateLabel from "@/components/DateLabel";
import ViewReport from "@/components/ViewReport";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";

export default {
  name: "LabChargeTask",
  props: {},
  components: {
    Paginator,
    DataTable,
    Column,
    ReportStatus,
    DateLabel,
    ViewReport,
    ConfirmDialog,
    Toast,
  },
  data() {
    return {
      selectedItem: [],
      display: false,
      reportFlags: reportFlags,
    };
  },
  setup() {
    const curPage = ref(1);
    const count = ref(0);
    const taskItems = ref([]);
    const status = ref(0);

    const countTask = async (status) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/wpsfile/countbylabcharge/${status}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + auth.state.token,
            },
          }
        );
        count.value = await response.json();
      } catch (error) {
        console.error(error);
      }
    };
    const fetchTask = async (status, page) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/wpsfile/listbylabcharge/${status}/${10}/${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + auth.state.token,
            },
          }
        );
        taskItems.value = await response.json();
      } catch (error) {
        console.error(error);
      }
    };
    watchEffect(() => {
      fetchTask(status.value, curPage.value);
    });
    onMounted(() => {
      countTask(status.value);
      fetchTask(status.value, curPage.value);
    });
    onUnmounted(() => {});

    return { count, taskItems, curPage, status, countTask };
  },
  watch: {
    status(newStatus) {
      this.countTask(newStatus);
      this.curPage = 1;
    },
  },
  computed: {},
  methods: {
    sampleno(sample) {
      return `${sample.year}-${
        sample.samplecode
      }-${sample.samplenumber.toString().padStart(4, "0")}`;
    },
    trustno(sample) {
      const temp = `${
        sample.trust.year
      }-${sample.trust.trustnumber.toString().padStart(4, "0")}`;
      if (sample.trust.certificate === 0) {
        return `JHWT-${temp}`;
      } else {
        return `JHSWT-${temp}`;
      }
    },
    middlereport(sample) {
      if (sample.ismiddlereport) {
        return sample.middlereportdate;
      } else {
        return "/";
      }
    },
    diffdate(reportdate) {
      const diff = (new Date(reportdate) - Date.now()) / (24 * 60 * 60 * 1000);
      if (diff <= 3) {
        return "red";
      } else if (diff > 3 && diff <= 5) {
        return "yello";
      } else {
        return "normal";
      }
    },

    urgent() {
      this.$confirm.require({
        message: "你确认要加急所选择的报告吗?",
        header: "再次确定提示",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const selectedId = this.selectedItem.map((c) => c.sample.id);
          (async () => {
            try {
              const res = await fetch(`${baseUrl}/api/v1/wpsfile/urgent/1`, {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.state.token,
                },
                body: JSON.stringify({ checkedid: selectedId }),
              });
              if (res.ok) {
                const json = await res.json();
                this.taskItems = this.taskItems.map((item) => {
                  return selectedId.indexOf(item.sample.id) !== -1
                    ? { ...item, wpsfile: { ...item.wpsfile, urgent: 1 } }
                    : item;
                });
                this.selectedItem = [];
                this.$toast.add({
                  severity: "success",
                  summary: "信息提示",
                  detail: json.msg,
                  life: 3000,
                });
              }
            } catch (error) {
              this.$toast.add({
                severity: "error",
                summary: "信息提示",
                detail: error.message,
                life: 6000,
              });
            }
          })();
        },
        reject: () => {
          this.$toast.add({
            severity: "success",
            summary: "取消",
            detail: "不执行",
            life: 3000,
          });
        },
      });
    },

    cancelUrgent() {
      this.$confirm.require({
        message: "你确认要取消加急所选择的报告吗?",
        header: "再次确定提示",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const selectedId = this.selectedItem.map((c) => c.sample.id);
          (async () => {
            try {
              const res = await fetch(`${baseUrl}/api/v1/wpsfile/urgent/0`, {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.state.token,
                },
                body: JSON.stringify({ checkedid: selectedId }),
              });
              if (res.ok) {
                const json = await res.json();
                this.taskItems = this.taskItems.map((item) => {
                  return selectedId.indexOf(item.sample.id) !== -1
                    ? { ...item, wpsfile: { ...item.wpsfile, urgent: 0 } }
                    : item;
                });
                this.selectedItem = [];
                this.$toast.add({
                  severity: "success",
                  summary: "信息提示",
                  detail: "取消加急" + json.msg,
                  life: 3000,
                });
              }
            } catch (error) {
              this.$toast.add({
                severity: "error",
                summary: "信息提示",
                detail: error.message,
                life: 6000,
              });
            }
          })();
        },
        reject: () => {
          this.$toast.add({
            severity: "success",
            summary: "取消",
            detail: "不执行",
            life: 3000,
          });
        },
      });
    },
    acceptTaskMany() {
      this.$confirm.require({
        message: "你确认要批量接收所勾选的任务吗?",
        header: "再次确定提示",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const selectedId = this.selectedItem.map((c) => c.sample.id);
          (async () => {
            try {
              const res = await fetch(
                `${baseUrl}/api/v1/wpsfile/accepttaskmany`,
                {
                  method: "put",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.state.token,
                  },
                  body: JSON.stringify({ checkedid: selectedId }),
                }
              );
              if (res.ok) {
                const json = await res.json();
                this.taskItems = this.taskItems.map((item) => {
                  return selectedId.indexOf(item.sample.id) !== -1
                    ? { ...item, wpsfile: { ...item.wpsfile, status: 1 } }
                    : item;
                });
                this.selectedItem = [];
                this.$toast.add({
                  severity: "success",
                  summary: "信息提示",
                  detail: json.msg,
                  life: 3000,
                });
              }
            } catch (error) {
              this.$toast.add({
                severity: "error",
                summary: "信息提示",
                detail: error.message,
                life: 6000,
              });
            }
          })();
        },
        reject: () => {
          this.$toast.add({
            severity: "success",
            summary: "取消",
            detail: "不执行",
            life: 3000,
          });
        },
      });
    },
    finish() {
      this.$confirm.require({
        message: "你确认要标识完结所选择的报告吗?",
        header: "再次确定提示",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const selectedId = this.selectedItem.map((c) => c.sample.id);
          (async () => {
            try {
              const res = await fetch(`${baseUrl}/api/v1/wpsfile/finish/10`, {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.state.token,
                },
                body: JSON.stringify({ checkedid: selectedId }),
              });
              if (res.ok) {
                const json = await res.json();
                this.taskItems = this.taskItems.map((item) => {
                  return selectedId.indexOf(item.sample.id) !== -1
                    ? { ...item, wpsfile: { ...item.wpsfile, status: 10 } }
                    : item;
                });
                this.selectedItem = [];
                this.$toast.add({
                  severity: "success",
                  summary: "信息提示",
                  detail: json.msg,
                  life: 3000,
                });
              }
            } catch (error) {
              this.$toast.add({
                severity: "error",
                summary: "信息提示",
                detail: error.message,
                life: 6000,
              });
            }
          })();
        },
        reject: () => {
          this.$toast.add({
            severity: "success",
            summary: "取消",
            detail: "不执行",
            life: 3000,
          });
        },
      });
    },

    unFinish() {
      this.$confirm.require({
        message: "你确认要重新开始报告吗?转为未接收状态?一切重来",
        header: "再次确定提示",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const selectedId = this.selectedItem.map((c) => c.sample.id);
          (async () => {
            try {
              const res = await fetch(`${baseUrl}/api/v1/wpsfile/finish/0`, {
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.state.token,
                },
                body: JSON.stringify({ checkedid: selectedId }),
              });
              if (res.ok) {
                const json = await res.json();
                this.taskItems = this.taskItems.map((item) => {
                  return selectedId.indexOf(item.sample.id) !== -1
                    ? { ...item, wpsfile: { ...item.wpsfile, status: 0 } }
                    : item;
                });
                this.selectedItem = [];
                this.$toast.add({
                  severity: "success",
                  summary: "信息提示",
                  detail: json.msg,
                  life: 3000,
                });
              }
            } catch (error) {
              this.$toast.add({
                severity: "error",
                summary: "信息提示",
                detail: error.message,
                life: 6000,
              });
            }
          })();
        },
        reject: () => {
          this.$toast.add({
            severity: "success",
            summary: "取消",
            detail: "不执行",
            life: 3000,
          });
        },
      });
    },

    onPage(event) {
      this.curPage = event.page + 1;
    },
  },
};
</script>

<style scoped>
.p-paginator-pages {
  background-color: brown;
}
.red {
  padding: 5px;
  border-radius: 3px;
  background-color: rgb(226, 44, 44);
  color: white;
}
.yello {
  background-color: yellow;
  color: white;
}

.normal {
  background-color: white;
  color: black;
}
.grap {
  margin-left: 20px;
}
h2 {
  text-align: center;
}
</style>
